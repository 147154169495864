<template>
  <div class="wrap">
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
        v-show="!backIcon"
      />
      <span style="font-size: 20px; color: #323233; font-weight: bold"
        >危急值</span
      >
    </div>
     <van-loading type="spinner" v-show="loading" />
    <div class="wrap_list" v-if="dataList.length > 0">
      <div class="list" @click="onClickInfo">
        <div class="list_title">待处理危急值</div>
        <van-badge dot ></van-badge>
        <van-icon
          v-if="dataList.length > 0"
          name="arrow"
          size="20"
          style="font-size: 20px; font-weight: bold; margin-right: 20px"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { getList } from "@/api/critical";
import { setStore, getStore } from "@/utils/localStorage.js";
import sha1 from "js-sha1";
export default {
  data() {
    return {
      loading:false,
      dataList: [],
      patientName: "",
      backIcon: localStorage.getItem("routerHistory"),
    };
  },
  created() {
    this.getData();
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: "/menu" });
    },
    onClickInfo() {
      this.$router.push({ path: "/criticalList" });
    },
    getData() {
      this.loading=true;
      getList(this.patientName).then((res) => {
        if (res.data.code == "200") {
          this.loading=false;
          this.dataList = res.data.data;
          console.log(this.dataList);
        }
      }).catch((err)=>{
          this.loading=false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-loading {
  left: 45%;
  top: 15px;
}
.wrap {
  padding-top: 20px;

  .nav_bar {
    text-align: center;
    color: #323233;
  }

  .wrap_list {
    padding-left: 20px;
    font-size: 16px;

    .list {
      display: flex;
      justify-content: space-between;
      height: 52px;
      line-height: 52px;
      align-items: center;
      border-bottom: 1px solid #dcdcdc;

      .list_title {
        color: #333;
        margin-left: 10px;
      }
    }
  }
}

.van-icon-arrow {
  color: #999;
}

/deep/.van-badge--fixed {
  top: 25px !important;
  right: -20px !important;
}
.van-badge--dot {
  margin-left: -175px;
}
</style>